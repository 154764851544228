<template>
  <div id="mapPlayPage"></div>
  <div id="targeter">
    <p v-if="!timerRunning" class="spacer-text">Tell us - is the parking slot free or taken?</p>
    <p v-if="timerRunning" class="spacer-text">Hint: it takes 2 minutes until you are able to earn your next 10 prken. coins.</p>
    <p v-if="timerRunning" class="spacer-text">Time Elapsed: {{ timeElapsed }} seconds</p>
    <div v-if="!timerRunning">
      <button class="targeter-button free" @click="fetchGPSLocation(true)" :disabled="isLoading">Free</button>
      <button class="targeter-button taken" @click="fetchGPSLocation(false)" :disabled="isLoading">Taken</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  data() {
    return {
      isLoading: false,
    }
  },
  setup() {
    const router = useRouter();
    return {
      router
    }
  },
  mounted() {
    var map = L.map('mapPlayPage', {
      zoomControl: false,
      center: [51.505, -0.09],
      zoom: 13
    });

    // L.tileLayer('https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.{ext}', {
    //   minZoom: 0,
    //   maxZoom: 20,
    //   attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    //   ext: 'png'}).addTo(map);

    L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
    }).addTo(map);

    // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    // }).addTo(map); 

    function onLocationFound(e) {
      var radius = e.accuracy;

      // Decorate map.
      L.marker(e.latlng).addTo(map);
      L.circle(e.latlng, radius).addTo(map);
    }

    map.on('locationfound', onLocationFound);
    map.locate({watch: false, setView: true, maxZoom: 17});
    
    if(this.$store.state.timerRunning) {
      this.isLoading = true;
    }
  },
  watch: {
    timeElapsed(newValue) {
      if (newValue >= 120) {
        this.isLoading = false; // Enable the button after 10 seconds
        this.$store.dispatch('resetTimer');
      }
    },
  },
  methods: {
    ...mapActions(['startTimer', 'stopTimer', 'resetTimer']),
    fetchGPSLocation(isFree) {
      if ('geolocation' in navigator) {
        this.isLoading = true;
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.sendParkingSpotStatusToServer(isFree, position.coords.latitude, position.coords.longitude);
            this.$store.dispatch('startTimer');
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            alert('Error getting location: ' + error.message);
          }
        );
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    },
    async sendParkingSpotStatusToServer(isFree, latitude, longitude) {
      this.isLoading = true;
      console.log(`Updating status to: ${isFree ? 'Free' : 'Occupied'}`);
      console.log(`GPS Location: ${latitude}, ${longitude}`);

      const payload = {
        geometry: {
          type: "Point",
          coordinates: [longitude, latitude]
        },
        timestamp: new Date().toISOString(),
        observationValue: isFree ? 1 : 0,
      };

      // POST json package to the server with a promise.
      axios.post('/api/parking-spots', payload)
        .then((response) => {
          console.log(response.data);
          this.router.push('/confirmation');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters(['timeElapsed', 'timerRunning']),
  },
}
</script>

<style>
#mapPlayPage { 
  position: relative;
  top: 20px;
  z-index: 1;
  height: 89vh;
  width: 320px;
  border-radius: 25px;
  margin: 0 auto;
}
#targeter {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0px auto;
  background-color: #FEF8F4;
  border-radius: 10px;
  box-shadow: 3px 3px 5px gray;
  height: 149px;
  position: absolute;
  z-index: 2;
  width: 270px;
  top: calc(75% - 75px);
  left: calc(50% - 135px);
}

.targeter-img {
  width: 100%;
  height: auto;
  margin: 30px auto;
}

.spacer-text {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.targeter-button {
  color: white;
  border: 0px solid #333333;
  border-radius: 30px;
  padding: 10px;
  margin: 10px;
  width: 100px;
  font-weight: 500;
  box-shadow: 2px 2px 5px wheat;
  font-size: 23px;
}

.free {
  background-color: #98ffc3;
}

.taken {
  background-color: #ff6086;
}

button:disabled{
  background-color: #cccccc;
  color: #666666;
}
</style>
