<!-- Basic Vue footer -->
<template>
  <footer>
    <p>&copy; {{ new Date().getFullYear() }} prken.</p>
  </footer>
</template>

<style>
footer {
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 270px;
}
</style>
