<template>
<div class="overlay">
  <div class="content">
    <div class="logo">
      <img src="../assets/prken_logo_white.svg" />
    </div>
    <h1 class="roboto-black, header-title">prken.</h1>
    <h2 class="roboto-regular, header-title">parking made easy</h2>
    <p class="roboto-regular, styled-paragraph">Are you tired of searching a free parking slot for hours?</p>
    <p class="roboto-regular, styled-paragraph">
      <strong>WHAT</strong>
      <br>
      Join the «prken.» mission. We leverage crowd intelligence insights to find the closest free parking slots near you - whenever and wherever you are in need of it.
    </p>
    <p class="roboto-regular, styled-paragraph, last-paragraph">
      <strong>HOW</strong>
      <br>
      Press play and start capturing free or occupied parking slots in your neighborhood. The more and the faster we gather data to feed our predictive algorithm model, the quicker prken. will become your daily companion. What are you waiting for?
    </p>
    <div class="cta">
      <button @click="enterApp">
        <img width="25%" src="../assets/White play button landing page.png" />
        <p>Press play to join the «prken.» mission.</p>
      </button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  methods: {
    enterApp() {
      this.$emit('enterApp');
    }
  }
};
</script>

<style>
    .overlay{
      position: fixed;
      overflow: auto;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      background-color: rgba(0, 0, 0, 1.0);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      z-index: 1000;
    }

    .logo {
      max-width: 700px;
      width: 70vw;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .logo img{
      width: 75px;
      margin: 15px;
    }

    .content {
      color: #ffffff;
      height: 100vh;
      width: 100vw;
      overflow: auto;
    }

    .styled-paragraph {
      max-width: 700px;
      width: 70vw;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    .last-paragraph {
      max-width: 700px;
      width: 70vw;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .cta {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: #f4c2ab;
      color: #000;
      height: 50vh;
      width: 100vw;
    }

    .cta p {
      color: white;
    }

    .cta button {
      background-color: transparent;
      border: none;
      font-size: 48px;
      cursor: pointer;
      margin-top: 30px;
    }

    .header-title {
      text-align: center;
    }

    h1 {
      font-size: 48px;
      margin: 0;
    }

    h2 {
      font-size: 24px;
      margin-top: -10px;
      margin-bottom: 25px;
    }

    p {
      font-size: 18px;
    }

    .roboto-thin {
      font-family: "Roboto", sans-serif;
      font-weight: 100;
      font-style: normal;
    }

    .roboto-light {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-style: normal;
    }

    .roboto-regular {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
    }

    .roboto-medium {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-style: normal;
    }

    .roboto-bold {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-style: normal;
    }

    .roboto-black {
      font-family: "Roboto", sans-serif;
      font-weight: 900;
      font-style: normal;
    }

    .roboto-thin-italic {
      font-family: "Roboto", sans-serif;
      font-weight: 100;
      font-style: italic;
    }

    .roboto-light-italic {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-style: italic;
    }

    .roboto-regular-italic {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: italic;
    }

    .roboto-medium-italic {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-style: italic;
    }

    .roboto-bold-italic {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-style: italic;
    }

    .roboto-black-italic {
      font-family: "Roboto", sans-serif;
      font-weight: 900;
      font-style: italic;
    }
</style>