<template>
    <div class="content-container confirmation-container">
        <img class="prken-coin-image flip-horizontal-bottom" src="../assets/prken_coin_V2.png" alt="Prken Coin">
        <p class="confirmation-text">Congrats, you just earned 10 prken. coins - keep it up!</p>
    </div>
</template>
  
<script>
export default {
name: 'AboutPage'
}
</script>

<style>

.confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.prken-coin-image{
  width: 69%;
  height: auto;
  margin: 60px auto;
}

.confirmation-text {
  width: 70%;
  text-align: center;
  margin-bottom: 50px;
}

.flip-horizontal-bottom {
	-webkit-animation: flip-horizontal-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	animation: flip-horizontal-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-8-13 14:0:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-horizontal-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  50% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  50% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }

}
</style>
