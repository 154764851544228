<template>
  <div class="content-container">
    <p class="title">Are you tired of searching a free parking slot for hours?</p>
    <br>
    <p>Then, we finally have the solution for you: Join the «prken.» mission. We leverage crowd intelligence insights to find the closest free parking slots near you - whenever and wherever you are in need of it.</p>
    <br>
    <p>To begin your own prken. mission press play and start capturing free or occupied parking slots in your neighborhood. The more and the faster we gather data to feed our predictive algorithm model, the faster prken. will become your daily companion.</p>
    <br>
    <p>Help yourself and others stop wasting time - so be quick.  ;)</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
}
</script>