<template>
  <PlayPage v-if="isAuthenticated" />
  <div v-else class="content-container">
    <div>
      <p class="title">Hi,</p>
      <p class="title">prken. hero</p>
      <p>No additional account or new password to remember.</p>
      <br>
      <p>Simply sign-in with your preferred SSO:</p>
    </div>
    <button @click="loginWithGoogle" class="sso-button google">
      <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" alt="Google logo">
      Continue with Google
    </button>
    <button class="sso-button facebook">
      <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
        alt="Facebook logo">
      Continue with Facebook
    </button>
    <button class="sso-button apple">
      <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" alt="Apple logo">
      Continue with Apple
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlayPage from './PlayPage.vue';

export default {
  name: 'HomePage',
  computed: {
    ...mapGetters(['user', 'isAuthenticated'])
  },
  components: {
    PlayPage,
  },
  methods: {
    loginWithGoogle() {
      window.location.href = process.env.VUE_APP_BACKEND_URL + '/auth/google';
    }
  }
}
</script>

<style>
.sso-button {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 270px;
  padding: 10px;
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.sso-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>
