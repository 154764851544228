<template>
  <LandingPage v-if="showLandingPage" @enterApp="enterApp"/>
  <Navigation />
  <router-view />
  <Footer />
</template>

<script>
import Footer from './components/FooterComponent.vue';
import LandingPage from './components/LandingPage.vue';
import Navigation from './components/NavigationComponent.vue';

export default {
  data() {
    return {
      showLandingPage: localStorage.getItem('showLandingPage') !== 'false',
    }
  },
  created() {
    this.$store.dispatch('fetchUser');
  },
  components: {
    LandingPage,
    Footer,
    Navigation
  }, 
  methods: {
    enterApp() {
      this.showLandingPage = false;
      localStorage.setItem('showLandingPage', 'false');
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Basic Body Styling */
body {
  font-family: "Quicksand", sans-serif;
  background-color: #FEF8F4;
  color: #333;
  line-height: 1.6;
}

/* App Container */
/* Center app div on screen */
#app {
  position: relative;
  margin: auto;
  min-width: 320px;
  max-width: 480px;
  min-height: 480px;
  /* outline: 1px solid black; */
}

.content-container {
  width: 270px;
  margin: 0 auto;
  text-align: left;
  padding-top: 140px;
}

/* Typographie */
.title {
  font-size: 24px;
  font-weight: 700;
}
</style>
