<template>
  <div id="header">
    <div id="icon-navigation">
      <router-link to="/navigation">
        <img class="navigation-icon" alt="Vue logo" src="../assets/logo.png">
      </router-link>
    </div>
  </div>
</template>

<style>
#header {
  position: absolute;
  right: calc(20px + (100% - 320px) * 0.51);
  top: 30px;
  margin: 0px 0px;
  width: 75px;
  display: flex;
  justify-content: right;
  padding: 0px;
  z-index: 2;
}

.navigation-icon {
  width: 75px;
  height: 75px;

}
</style>