import { createStore } from 'vuex';
import axios from 'axios';
import router from './router';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const store = createStore({
  state: {
    user: null,
    timerRunning: false,
    timeElapsed: 0,
    timerInterval: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
    startTimer(state) {
      if (state.timerInterval) return;

      state.timerRunning = true;
      state.timerInterval = setInterval(() => {
        state.timeElapsed += 1;
      }, 1000);
    },
    stopTimer(state) {
      clearInterval(state.timerInterval);
      state.timerInterval = null;
      state.timerRunning = false;
    },
    resetTimer(state) {
      state.timeElapsed = 0;
      clearInterval(state.timerInterval);
      state.timerInterval = null;
      state.timerRunning = false;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const response = await axios.get('/api/user');
        console.log('User data:', response.data);
        commit('setUser', response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log('User is not authenticated');
          return;
        }
        console.log('Error fetching user');
        console.error(error);
        commit('clearUser');
      }
    },
    logout({ commit }) {
      commit('clearUser');
      router.push('/');
    },
    startTimer({ commit }) {
      commit('startTimer');
    },
    stopTimer({ commit }) {
      commit('stopTimer');
    },
    resetTimer({ commit }) {
      commit('resetTimer');
    },
  },
  getters: {
    user: state => state.user,
    isAuthenticated: state => !!state.user,
    timeElapsed: (state) => state.timeElapsed,
    timerRunning: state => state.timerRunning,
  }
});

export default store;
