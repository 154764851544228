<template>
  <div class="content-container">
    <h1>User information</h1>
    <p>Avatar: {{ user.avatar }}</p>
    <div class="vertical-radio-group">
      <div>
        <input type="radio" id="witful_betty" value="witful_betty" v-model="user.avatar" />
        <label for="witful_betty">Witful Betty</label>
      </div>
      <div>
        <input type="radio" id="misty_the_unicorn" value="misty_the_unicorn" v-model="user.avatar" />
        <label for="misty_the_unicorn">Misty the Unicorn</label>
      </div>
      <div>
        <input type="radio" id="speedy_tom" value="speedy_tom" v-model="user.avatar" />
        <label for="speedy_tom">Speedy Tom</label>
      </div>
    </div>
    <p>Email: {{ user.userMetadata.email }}</p>
    <img :src="user.userMetadata.picture" alt="Profile Picture">
    <br>
    <button @click="saveUserData()" :disabled="isLoading">Save Changes</button>
    <br>
    <button @click="logout">Logout</button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { onMounted, ref } from 'vue';
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'SettingsPage',
  data() {
    return {
      isLoading: false
    }
  },
  setup() {
    // Provide default data for rendering.
    const user = ref({
      avatar: '',
      userMetadata: {
        email: '',
        picture: ''
      }
    });
    onMounted(async () => {
      const response = await axios.get('/api/query-user-data');
      user.value = response.data[0];
      console.log(user.value);
    });

    return {
      user
    };
  },

  mounted() {
    console.log('bli bla blu') // 0
  },

  methods: {
    ...mapActions(['logout']),
    async saveUserData() {
      this.isLoading = true;
      console.log('Saving user data');
      console.log(this.user);
      const response = await axios.post('/api/update-user-data', this.user);
      console.log(response);
      this.isLoading = false;
    }
  }
}
</script>
