<template>
  <div class="content-container">
    <button @click="queryObservations" :disabled="isLoading">Query Observations</button>
    <ol>
      <li v-for="observation in observations" :key="observation.id">
        Coordinates: {{ observation.geometry.coordinates }} at {{ observation.timestamp }} with value: {{
          observation.observationValue }}
      </li>
    </ol>
  </div>
</template>

<script>
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  data() {
    return {
      isLoading: false,
      observations: []
    }
  },
  methods: {
    async queryObservations() {
      this.isLoading = true;
      axios.get('/api/parking-spots')
        .then((response) => {
          console.log(response.data);
          this.observations = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>
