<template>
    <div class="content-container">
        Your current rank is {{leaderboard.rank}} with {{ leaderboard.count }} observations.
        <br>
        Keep going.
        <div id="map"></div>
    </div>
</template>

<script>
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  data() {
    return {
      leaderboard: [],
      observations: []
    }
  },
  mounted() {
    var map = L.map('map', {
      zoomControl: true,
      center: [47.3509, 8.3757],
      zoom: 9
    });

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    this.queryLeaderboard();
    this.queryObservations(map);
  },
  methods: {
    async queryLeaderboard() {
      axios.get('/api/query-leaderboard')
        .then((response) => {
          console.log(response.data);
          this.leaderboard = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });
    },
    async queryObservations(map) {
      axios.get('/api/parking-spots')
        .then((response) => {
          console.log(response.data);
          this.observations = response.data;
          this.observations.forEach(entry => {
          const coordinates = entry.geometry.coordinates;
          console.log(`Coordinates: Latitude = ${coordinates[1]}, Longitude = ${coordinates[0]}`);
          L.marker([coordinates[1], coordinates[0]]).addTo(map);
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });
    }
  }
}

</script>

<style>
#map { height: 270px; width: 270px; margin-top: 60px;}
</style>