<template>
  <div v-if="isAuthenticated" class="navigation-toolbar">
    <router-link class="nav-button" to="/prkenland">Prken Land</router-link>
    <router-link class="nav-button" to="/play">Play</router-link>
    <router-link class="nav-button" to="/leaderboard">Leader Board</router-link>
    <router-link class="nav-button" to="/settings">Account</router-link>
  </div>
  <div v-else class="navigation-toolbar">
    <router-link class="nav-button" to="/">Home</router-link>
    <router-link class="nav-button" to="/about">About</router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
};
</script>

<style>
.navigation-toolbar {
  display: flex;
  flex-direction: column;
  padding-top: 140px;
}

.nav-button {
  width: 270px;;
  padding: 5px;
  margin: 10px auto;
  border-radius: 5px;
  background: #ffffff;
  outline: 1px solid #ccc;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 500;
}
</style>