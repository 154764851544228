<template>
  <div id="mapPrkenLand"></div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Configure Axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

// Configure Leaflet icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'PrkenLandPage',
  setup() {
    const map = ref(null);
    const observations = ref([]);
    const circleLayerGroup = ref(null);

    const queryObservations = async () => {
      try {
        const response = await axios.post('/api/all-observations-last-n-days', {
          days: 1
        });
        console.log(response.data);
        observations.value = response.data;

        observations.value.forEach(entry => {
          const fillColor = (entry.observationValue === 1) ? '#98ffc3' : '#ff6086';
          const coordinates = entry.geometry.coordinates;
          console.log(`Coordinates: Latitude = ${coordinates[1]}, Longitude = ${coordinates[0]}`);
          const circleOptions = {
            fillColor: fillColor,
            fillOpacity: 0.5,
            stroke: true,
            color: fillColor,
            radius: 20
          };
          L.circle([coordinates[1], coordinates[0]], circleOptions).addTo(circleLayerGroup.value);
        });
      } catch (error) {
        console.error(error);
      }
    };

    const onLocationFound = (e) => {
      const radius = e.accuracy;
      console.log(radius);
    };

    const onMapZoomEnd = () => {
      const zoom = map.value.getZoom();
      console.log(`Current Zoom Level: ${zoom}`);

      // Adjust circle radii based on zoom level
      circleLayerGroup.value.getLayers().forEach(layer => {
        if (layer instanceof L.Circle) {
          if (zoom <= 17) {
            const newRadius = -20 * zoom + 360; // Adjust the scaling factor as needed
            layer.setRadius(newRadius);
          }
        }
      });
    };

    onMounted(() => {
      map.value = L.map('mapPrkenLand', {
        zoomControl: false,
        center: [51.505, -0.09],
        zoom: 13
      });

      L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
      }).addTo(map.value);

      map.value.on('locationfound', onLocationFound);
      map.value.on('zoomend', onMapZoomEnd);
      map.value.locate({ watch: false, setView: true, maxZoom: 17 });

      circleLayerGroup.value = L.layerGroup().addTo(map.value);
      queryObservations();
    });

    return {
      // Expose any reactive properties or methods if needed
    };
  },
};
</script>

<style>
#mapPrkenLand {
  position: relative;
  top: 20px;
  z-index: 1;
  height: 89vh;
  width: 320px;
  border-radius: 25px;
  margin: 0 auto;
}
</style>